import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useDumaStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      bannerCase: file(
        relativePath: { eq: "case-study/duma/case-banner.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredOne: file(
        relativePath: { eq: "case-study/duma/solution-delivered-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredTwo: file(
        relativePath: { eq: "case-study/duma/solution-delivered-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredThree: file(
        relativePath: { eq: "case-study/duma/solution-delivered-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFour: file(
        relativePath: { eq: "case-study/duma/solution-delivered-4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFive: file(
        relativePath: { eq: "case-study/duma/solution-delivered-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSix: file(
        relativePath: { eq: "case-study/duma/solution-delivered-6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoCaseImage: file(
        relativePath: { eq: "case-study/duma/video-case.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)
  return query
}

export default useDumaStaticQuery
