// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bN_kr";
export var caseStudyBackground__lineColor = "bN_kp";
export var caseStudyCta__bgColor = "bN_kG";
export var caseStudyHead__imageWrapper = "bN_kk";
export var caseStudyProjectsSection = "bN_ks";
export var caseStudyQuote__bgDark = "bN_mn";
export var caseStudyQuote__bgLight = "bN_kt";
export var caseStudyQuote__bgRing = "bN_km";
export var caseStudySolution__ring = "bN_ky";
export var caseStudySolution__ringThree = "bN_mm";
export var caseStudySolution__ringTwo = "bN_kz";
export var caseStudyVideo = "bN_kB";
export var caseStudyVideo__ring = "bN_kv";
export var caseStudy__bgDark = "bN_kh";
export var caseStudy__bgLight = "bN_kg";