import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import solutionBgRingOne from '~/assets/images/case-study/duma/bg-solution-rings-1.svg'
import solutionBgRingTwo from '~/assets/images/case-study/duma/bg-solution-rings-2.svg'
import solutionBgRingThree from '~/assets/images/case-study/duma/bg-solution-rings-3.svg'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Duma.module.scss'
import useDumaStaticQuery from './useDumaStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'James Cannon Boyce',
    position: 'Founder of Africa New Media Group',
  },
  be: {
    name: 'Maksym',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewRefTitle = 'Dental Imaging Management System'
const previewRewTitle = 'Recruitment Progressive Web Application'

const CaseStudiesDuma = () => {
  const query = useDumaStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyHead
          title="Highload News Aggregator for DUMA™"
          subtitle="Learn how Codica helped the African media market leader scale the existing solution, migrate to AWS without downtime, and improve the page load speed time by 2 times on mobile devices."
          maxWidthSubtitle="425px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Highload news aggregator for Duma"
          imageTitle="News aggregator built with Ruby on Rails"
          domain="Online Media"
          location="Africa"
          timeline="July 2019 - Ongoing"
          services={
            <>
              Front-end, Back-end,
              <Link
                to="/services/mvp-development/"
                className="ml5"
                key="mvpDevKey"
              >
                MVP development services
              </Link>
              ,
              <Link
                to="/services/custom-software-development/"
                className="ml5"
                key="pwaDevKey"
              >
                Custom software development services
              </Link>
            </>
          }
          team="1 Frontend Developer, 1 Backend Developer, 1 QA Engineer, 1 Project Manager, 1 DevOps Engineer"
          technologies="Ruby, Ruby on Rails, AWS, Sidekiq, Redis, Gitlab CI, JavaScript"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“We are the biggest and the most popular online news portal in Africa. In 2019 we were changing software service providers and came across a young, dynamic team from Ukraine, Codica, with great expertise and an impressive portfolio in custom software development.',
            },
            {
              text: 'We started our collaboration with some minor platform changes and redesign. Later on, Codica provided a custom solution for our main website which helped us improve the page load speed. We now have a complex, scalable news platform, optimized for search engines. Thanks to this, we can offer a new customer experience in the media market.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-duma.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTitleLeftList="Challenges"
        bgTextLeft={[
          'The Africa New Media Group is the #1 entertainment news provider in Africa. The main idea behind the product is to deliver news and quality multimedia content to the African audience fast, especially on mobiles.',
          <div className="mb-3" key="bgTextLeft" />,
          'The news portal has one main website (DUMA™ news aggregator) covering several themes or ecosystems. Each ecosystem consists of simple websites whose posts are automatically cloned to the main aggregator website.',
        ]}
        bgTextRight={[
          'The customer wanted to create a unified back-end solution to be able to easily set up new websites within the existing ecosystems or create new ones.',
          <div className="mb-3" key="bgTextRight" />,
          'In 2019, when we started our work, there were 5 websites in total for several locations only. Currently, DUMA™ news portal covers 11 locations in Africa and has 2 ecosystems: Celebrity News and Fashion, with 17 live websites and more under development.',
        ]}
        sectionBusiness={[
          {
            label: (
              <>
                Make quality
                <Link
                  to="/services/ui-ux-design/website-redesign-services/"
                  className="mx5"
                  key="keyCaseSolutionRedesignKey"
                >
                  redesign website
                </Link>
                of the existing solution based on the client’s designs and
                requirements;
              </>
            ),
          },
          {
            label: 'Integrate ads and sponsored content;',
          },
          {
            label: 'Optimize the monolithic web application for mobile use;',
          },
          {
            label:
              'Create one universal back-end solution with a different front-end for every ecosystem;',
          },
          {
            label: 'Migrate to AWS without downtime.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Set up a convenient system for integrating advertising and sponsored content as one of the ways of the news portal monetization;',
          },
          {
            label:
              'Performed website optimization for search engines for all ecosystems;',
          },
          {
            label:
              'Improved the page load speed time by two times on mobile devices even with an unstable Internet connection;',
          },
          {
            label: 'Performed migration from Heroku to AWS without downtime;',
          },
          {
            label:
              'Created 17 live websites and more under development for different countries of Africa;',
          },
          {
            label:
              'Enhanced performance of the existing high-load news aggregator;',
          },
          {
            label:
              'Optimized the development time of the new websites and ecosystems with the ability to constantly scale the portal.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Our collaboration started with website redesign services, and minor changes including Ruby on Rails development. Later on, we found many great opportunities for DUMA™ platform improvement, and eventually managed to help our client’s business grow from 5 websites to 35.',
            },
            {
              text: 'Additionally, our team did an excellent job on the website optimization for search engines, improving the pages load time. We also provided MVP development for another client’s idea, a solution for a freelance portal within the aggregator.',
            },
            {
              text: 'Interesting work on such high-load systems as DUMA™ and the real value we deliver to the customer boost my daily motivation.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Max.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudyCta__bgColor}
        title="Looking for an expert team to optimize your website for search engines?"
        description="We know how to solve your business challenge with a custom software solution."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Multi-location news portal"
          description={[
            'Currently, DUMA™ news aggregator covers 11 African locations and two ecosystems: Fashion and Celebrity News.',
            <div className="mb-2" key="descr" />,
            'These ecosystems have 17 live websites and a few more under development.',
            <div className="mb-2" key="descrNext" />,
            'These simple websites have a similar design and functionality.',
          ]}
          alt="Multi-location news portal in Africa"
          imageTitle="Real-time news portal in Africa about fashion and celebs"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="High-load news aggregator"
          description={[
            'DUMA™ shares real-time news about celebrities, fashion, sports, lifestyle, and more on a daily basis.',
            <div className="mb-2" key="descr" />,
            'The platform’s target audience is mobile users, who spend much time on the website and interact with its content.',
            <div className="mb-2" key="descrNext" />,
            'The platform can manage up to 1,000 users simultaneously. Thanks to this, the traffic grew to 1 million page views a month.',
          ]}
          alt="Real-time news portal in Africa"
          imageTitle="Mobile-friendly news portal in Africa"
          bgRings
          bgRingImage={solutionBgRingOne}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Website speed optimization"
          description={[
            'Internet connection speed in Africa may be as low as 0.30/4.63 (download/upload), on average not exceeding 20 Mbps.',
            <div className="mb-2" key="descr" />,
            'Since the local Internet is slow, our main task was to optimize the website loading speed for mobile users.',
            <div className="mb-2" key="descrNext" />,
            'We have improved the page load speed time by two times for images, third-party scripts, and ads.',
          ]}
          alt="Website speed optimization service"
          imageTitle="Website speed optimization geared to SEO ranking growth"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Convenient admin panel"
          description="DUMA™ news website has a user-friendly admin panel. With its help, a team of editors can instantly post content to each website, schedule news posts, and easily clone posts from other ecosystems."
          alt="Ruby on Rails development expertise"
          imageTitle="Admin panel that is easy-to-use thanks to Ruby on Rails development"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Easy start for new ecosystems"
          description={[
            'Currently, the DUMA™ aggregator consists of two ecosystems. But it’s not a limit.',
            <div className="mb-2" key="descr" />,
            'Codica’s development team can now easily clone new ecosystems. This gives the business owners an opportunity to scale up the platform and grow fast.',
          ]}
          alt="Bringing your ideas into reality with a custom software development company"
          imageTitle="Custom software development being flexible and scalable"
          bgRings
          bgRingImage={solutionBgRingTwo}
          bgRingClass={containerStyles.caseStudySolution__ringTwo}
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Extensive opportunities for sponsored content"
          description={[
            'DUMA™ created an excellent opportunity for different affiliate programs and local publishers.',
            <div className="mb-2" key="descr" />,
            'Ads and sponsored content are shown as native banner ads, as well as in The Hot Box section on all the websites.',
          ]}
          alt="Sponsored content shown on the online news portal."
          imageTitle="Native banner ads running on the online news portal."
          bgRings
          bgRingImage={solutionBgRingThree}
          bgRingClass={containerStyles.caseStudySolution__ringThree}
        />
      </div>

      <div className={containerStyles.caseStudyVideo}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description="Take a look at how this online news portal works. Currently the news aggregator includes 17 live websites with more under development."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/vi2CqpMqwBw"
          />
        </LazyHydrate>
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'Ruby', icon: 'ruby' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Redis', icon: 'redis' },
            { label: 'Gitlab CI', icon: 'gitlab' },
            { label: 'JavaScript', icon: 'js' },
          ]}
          integrations={[
            { label: 'Google Tag Manager', icon: 'googleTagManager' },
            { label: 'Google Ads', icon: 'googleReklama' },
            { label: 'Taboola', icon: 'taboola' },
          ]}
        />
      </div>

      <div className={containerStyles.caseStudyQuote__bgDark}>
        <CaseStudyQuote
          isBottom
          quote={[
            {
              text: '“What we liked most about our mutual work is Codica’s good understanding of the media domain, ads and sponsored content importance, and the team’s professional communication.',
            },
            {
              text: 'Codica helped avoid massive downtimes when we were moving our platform to AWS and came up with a timely MVP solution for platform collaborators.',
            },
            {
              text: 'We are now working together to make DUMA™ even better and faster. We are glad to have a reliable software development partner that helps us grow.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/clients/client-duma.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewRefTitle}
              color="refColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-ref.png"
                  alt={`Case study: ${previewRefTitle} | Codica`}
                  title={`Case study: ${previewRefTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="dental-imaging-management-system"
            />
            <CaseStudyPreview
              title={previewRewTitle}
              color="rewColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/rew/hiring-progressive-web-app.png"
                  alt={`Case study: ${previewRewTitle} | Codica`}
                  title={`Case study: ${previewRewTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="recruitment-progressive-web-application"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesDuma
